import React from "react";
import "../home_right/style.css";
import Usman from "../../assets/img/Usman.png";
import { Container, Row, Col, Image } from "react-bootstrap";
import SocialIcons from "../social_icons";

function Home_Right() {
  return (
    <Container className="home-right">
      <Row className="home-right-main">
        <Col xl={12}>
          <div className="img-container">
            <Image
              src={Usman}
              className="home-right-main-img"
              alt="Image of Usman Arif"
            />
          </div>
        </Col>
        <Col xl={12}>
          <Row className="home-right-footer">
            <SocialIcons />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Home_Right;
